declare var $: any;
import { InviteFunctions } from './invites';
import { QuoteFunctions } from './quotes';
import { CookieFunctions } from './cookiemessage';
declare const IS_DEV_BUILD: boolean; // The value is supplied by Webpack during the build
declare const VERSION: string; // The value is supplied by Webpack during the build
declare const LIVE_URL_IMAGES: string; // The value is supplied by Webpack during the build
import * as Sentry from '@sentry/browser';
declare let gtag: Function;
// import { Integrations } from '@sentry/tracing';
// import { RewriteFrames } from '@sentry/integrations';

if (!IS_DEV_BUILD) {

  Sentry.init({
    dsn: 'https://68dc5a7baeed492e81f53b29c14868e7@o460993.ingest.sentry.io/5462155',
    release: `v-${VERSION}`,
    // integrations: [new Integrations.BrowserTracing()],
    // tracesSampleRate: 0.25,
    // debug: true,
    // beforeSend(event) {
    //  alert('Event!');
    //  console.log(event);
    //  return event;
    // },
  });

} else {

  // Sentry.init({
  //  dsn: 'https://a14734e2f34e483fb65ac3c289bec28e@o460993.ingest.sentry.io/5462176',
  //  release: `v-${VERSION}`,
  //  integrations: [new Integrations.BrowserTracing()],
  //  tracesSampleRate: 1.0,
  // });
}

export class Index {

  public inviteFunctions: InviteFunctions;
  public quoteFunctions: QuoteFunctions;
  public cookieFunctions: CookieFunctions;
  private resizeTask: any = null;
  private resizeListener: any = null;
  private resizeListenerEvent: any = null;
  private isTouch: boolean = false;
  private touchstartListener: any = null;
  private touchstartListenerEvent: any = null;
  private liveUrlImages: string = LIVE_URL_IMAGES;
  
  constructor() {
    this.inviteFunctions = new InviteFunctions;
    this.quoteFunctions = new QuoteFunctions;
    this.cookieFunctions = new CookieFunctions;
    console.log('Home invoked!');
  }

  setupTouchListener() {

    this.touchstartListener = () => {
      if (this.resizeListenerEvent) {
        window.removeEventListener('resize', this.resizeListenerEvent);
      }
      window.addEventListener('orientationchange', this.resizeListenerEvent);
      this.isTouch = true;
      if (this.touchstartListenerEvent) {
        window.removeEventListener('touchstart', this.touchstartListenerEvent);
      }
    };

    this.touchstartListenerEvent = this.touchstartListener.bind(this);
    window.addEventListener('touchstart', this.touchstartListenerEvent);
  }

  setupResizeListener() {

    this.resizeListener = () => {

      if (this.resizeTask !== null) {
        clearTimeout(this.resizeTask);
      }

      this.resizeTask = setTimeout(
        () => {
          this.resizeTask = null;
          this.quoteFunctions.recreateSiema();
        },
        delay);

    };

    const delay = 100;
    this.resizeListenerEvent = this.resizeListener.bind(this);
    window.addEventListener('resize', this.resizeListenerEvent);
  }

  setupBgVideo() {
    $(document).ready(() => {
      // TODO - add webm/ogg
      const v = $('.home-video-container__intro-video');
      if (v.length) {
        $('#source-mp4').attr('src', `${this.liveUrlImages}home/old-intro-video.mp4`);
        if (v[0].autoplay) {
          v[0].load();
          v.bgVideo({
            fadeIn: 500,
            showPausePlay: false,
          });
        }
      }
    });
  }

  getCookie(name: String) {
    const v = document.cookie?.match(`(^|;) ?${name}=([^;]*)(;|$)`);
    return v ? v[2] : null;
  }

  analytics() {
    // only submit to analytics if cookie consent allows it!
    // TODO - test event correctly sent to Analytics if cookie consent
    // and not sent if no consent
    const consentCookie = this.getCookie('cpt-cookie-consent');

    if (!consentCookie || consentCookie.indexOf('-analytics') === -1) {
      return;
    }

    // console.log(window.location);
    // console.log(document.title);

    if (typeof (gtag) !== 'undefined') {
      gtag('js', new Date());
      gtag('config', 'G-PMPJFNGGSE');
      // gtag('event', 'page_view', {
        // page_title: document.title,
        //// page_location: window.location.pathname,
        // page_path: window.location.pathname,
      // });
    }
  }

}

const indexJs = new Index();
if ($('.home-video-container__intro-video').length) {
  indexJs.setupBgVideo();
}

const hasSiema: boolean = document.getElementsByClassName('quotes-siema').length > 0;
if (hasSiema) {
  indexJs.quoteFunctions.setupSiema();
}

// indexJs.inviteFunctions.setupModal();
indexJs.inviteFunctions.setupTrial();
indexJs.inviteFunctions.setupForm();
indexJs.inviteFunctions.initialise(false);
indexJs.cookieFunctions.setupMessage();
indexJs.setupResizeListener();
indexJs.setupTouchListener();
indexJs.analytics();
